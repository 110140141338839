export default {
	messages: {
		ru: {
			'Cтворюйте підписки': 'Создавайте подписки',
			'Дізнавайтеся першими про нові пропозиції з цікавими вам параметрами.': 'Узнавайте первыми о новых предложениях с интересными вам параметрами.',
			'Дивіться рекомендації': 'Смотрите рекомендации',
			'Ми вивчимо ваші вподобання, щоб допомогти знайти ваш кращий варіант швидше.': 'Мы изучим ваши предпочтения, чтобы помочь найти ваш лучший вариант скорее.',
			'Зберігайте цікаве у Обраному': 'Храните интересное в Избранном',
			'Дивіться збережені пропозиції з будь-яких пристроїв навіть без інтернету.': 'Смотрите сохраненные предложения с любых устройств даже без интернета.',
			'Спілкуйтесь у Чаті': 'Общайтесь в Чате',
			'Отримуйте миттєві сповіщення про нові повідомлення.': 'Получайте мгновенные уведомления о новых сообщениях.',
			'Продавайте швидко': 'Продавайте быстро',
			'Додавайте свої пропозиції, редагуйте, піднімайте в ТОП та отримуйте дзвінки покупців.': 'Добавляйте свои предложения, редактируйте, поднимайте в ТОП и получайте звонки покупателей.',
		},
		uk: {
		  	'Cтворюйте підписки': 'Cтворюйте підписки',
		  	'Дізнавайтеся першими про нові пропозиції з цікавими вам параметрами.': 'Дізнавайтеся першими про нові пропозиції з цікавими вам параметрами.',
		  	'Дивіться рекомендації': 'Дивіться рекомендації',
		  	'Ми вивчимо ваші вподобання, щоб допомогти знайти ваш кращий варіант швидше.': 'Ми вивчимо ваші вподобання, щоб допомогти знайти ваш кращий варіант швидше.',
		  	'Зберігайте цікаве у Обраному': 'Зберігайте цікаве у Обраному',
		  	'Дивіться збережені пропозиції з будь-яких пристроїв навіть без інтернету.': 'Дивіться збережені пропозиції з будь-яких пристроїв навіть без інтернету.',
		  	'Спілкуйтесь у Чаті': 'Спілкуйтесь у Чаті',
		  	'Отримуйте миттєві сповіщення про нові повідомлення.': 'Отримуйте миттєві сповіщення про нові повідомлення.',
		  	'Продавайте швидко': 'Продавайте швидко',
		  	'Додавайте свої пропозиції, редагуйте, піднімайте в ТОП та отримуйте дзвінки покупців.': 'Додавайте свої пропозиції, редагуйте, піднімайте в ТОП та отримуйте дзвінки покупців.',
		},
	}
};
