import { mapGetters } from 'vuex';
import appButtons from '../VideoLanding/components/AppButtons/index.vue';
import sendSms from '../VideoLanding/components/SendSms/index.vue';
import carouselMobileApp from './Carousel/index.vue';
import mobileAPP from '../Cabinet/components/mobileAPP/index.vue';
import verifiedByPeople from '../Common/VerifiedByPeople/index.vue';

export default {
  name: 'MobileApp',
  metaInfo() {
    return {
      title: `AUTO.RIA – ${this.$t('Мобільний додаток скачати на Андроїд або iOS(iPhone)')}`,
      description: this.$t('У додатку AUTO.RIA швидкий продаж, зручний пошук та вибір серед перевірених авто. Щомісяця 6 500 000+ українців купують та продають авто на'),
      meta: [
        { name: 'description', content: 'AUTO.RIA' },
        { property: 'og:site_name', content: 'AUTO.RIA' },
        {
          property: 'og:title', content: `AUTO.RIA – ${this.$t('Мобільний додаток завантажити на Андроїд або iOS(iPhone)')}`,
        },
        {
          property: 'og:description',
          content: this.$t('У додатку AUTO.RIA швидкий продаж, зручний пошук та вибір серед перевірених авто. Щомісяця 6 500 000+ українців купують та продають авто на AUTO.RIA'),
        },
        { property: 'og:image', content: 'https://css.riastatic.com/images/autoria512.png' },
        { name: 'ROBOTS', content: 'INDEX,FOLLOW' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/mobile_app/' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/mobile_app/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/mobile_app/' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/mobile_app/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/mobile_app/' },
        { rel: 'canonical', href: `https://auto.ria.com${this.langPrefix || ''}/mobile_app/` },
      ],
    };
  },
  data() {
    return {
      os: '',
      huaweiRegex: /huawei|(nexus\s6p|vog-l29|ane-lx1|INE-LX1|eml-l29|ele-l29)|android.+(bah2?-a?[lw]\d{2})|(MediaPad|MediaPad 7 Youth|IDEOS S7|S7-201c|S7-202u|S7-101|S7-103|S7-104|S7-105|S7-106|S7-201|S7-Slim|M2-A01L|BAH-L09|BAH-W09|AGS-L09|CMR-AL19)/i,
      setStickyClass: false,
    };
  },
  components: {
    appButtons,
    sendSms,
    carouselMobileApp,
    mobileAPP,
    verifiedByPeople,
  },
  computed: {
    ...mapGetters({
      segment: 'others/segment',
      lang: 'lang/id',
      isWebView: 'shared/device/isWebView',
    }),
  },
  mounted() {
    this.getMobileOs();
    this.handleLogs(0);
  },
  methods: {
    getMobileOs() {
      if (navigator && navigator.userAgent.match(this.huaweiRegex)) {
        this.os = 'huawei';
      } else if (navigator && navigator.userAgent.match(/Android/i)) {
        this.os = 'android';
      } else if (navigator && navigator.userAgent.match(/iPhone|iPad|iPod|Mac/i)) {
        this.os = 'ios';
      }
    },
    removeStickyFromBtn() {
      const IOW = require('../../helpers/IntersectionObserver_screen__01');
      IOW.add(this.$refs.sticky, (entry = {}) => {
        if (entry.isIntersecting) {
          this.setStickyClass = true;
        } else {
          this.setStickyClass = false;
        }
      });
    },
    handleLogs(clickAction) {
      this._slonik({
        event_id: 1101,
        click_action: clickAction,
        action_block: 1,
        current_url: window.location.pathname,
        previous_url: document.referrer,
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        'Мобільний додаток скачати на Андроїд або iOS(iPhone)': 'Мобильное приложение скачать на Андроид или iOS(iPhone)',
        'У додатку AUTO.RIA швидкий продаж, зручний пошук та вибір серед перевірених авто. Щомісяця 6 500 000+ українців купують та продають авто на': 'В приложении AUTO.RIA быстрая продажа, удобный поиск и выбор среди проверенных авто. Каждый месяц 6 500 000+ украинцев покупают и продают авто на',
        'У додатку AUTO.RIA швидкий продаж, зручний пошук та вибір серед перевірених авто. Щомісяця 6 500 000+ українців купують та продають авто на AUTO.RIA': 'В приложении AUTO.RIA быстрая продажа, удобный поиск и выбор среди проверенных авто. Каждый месяц 6 500 000+ украинцев покупают и продают авто на AUTO.RIA',
        'Все для купівлі та продажу': 'Все для покупки и продажи',
        'у додатку': 'в приложении',
        Вже: 'Уже',
        завантажень: 'загрузок',
        'Спробуйте і ви переваги додатку': 'Попробуйте и вы преимущества приложения',
      },
      uk: {
        'Мобільний додаток скачати на Андроїд або iOS(iPhone)': 'Мобільний додаток скачати на Андроїд або iOS(iPhone)',
        'У додатку AUTO.RIA швидкий продаж, зручний пошук та вибір серед перевірених авто. Щомісяця 6 500 000+ українців купують та продають авто на': 'У додатку AUTO.RIA швидкий продаж, зручний пошук та вибір серед перевірених авто. Щомісяця 6 500 000+ українців купують та продають авто на',
        'У додатку AUTO.RIA швидкий продаж, зручний пошук та вибір серед перевірених авто. Щомісяця 6 500 000+ українців купують та продають авто на AUTO.RIA': 'У додатку AUTO.RIA швидкий продаж, зручний пошук та вибір серед перевірених авто. Щомісяця 6 500 000+ українців купують та продають авто на AUTO.RIA',
        'Все для купівлі та продажу': 'Все для купівлі та продажу',
        'у додатку': 'у додатку',
        Вже: 'Вже',
        завантажень: 'завантажень',
        'Спробуйте і ви переваги додатку': 'Спробуйте і ви переваги додатку',
      },
    },
  },
};
