import {mapGetters} from 'vuex';

export default {
  name: 'CarouselMobileApp',
  data() {
	return {
	  index: 0,
	  isActive: false,
	  touch: {x: 0},
	  slides: [
		{
		  img: 'https://cdn.riastatic.com/docs/pictures/common/0/99/9922/9922.jpg',
		  title: this.$t('Cтворюйте підписки'),
		  description: this.$t('Дізнавайтеся першими про нові пропозиції з цікавими вам параметрами.'),
		  id: 1,
		},
		{
		  img: 'https://cdn.riastatic.com/docs/pictures/common/0/99/9923/9923.jpg',
		  title: this.$t('Дивіться рекомендації'),
		  description: this.$t('Ми вивчимо ваші вподобання, щоб допомогти знайти ваш кращий варіант швидше.'),
		  id: 2,
		},
		{
		  img: 'https://cdn.riastatic.com/docs/pictures/common/1/148/14864/14864.jpg',
		  title: this.$t('Зберігайте цікаве у Обраному'),
		  description: this.$t('Дивіться збережені пропозиції з будь-яких пристроїв навіть без інтернету.'),
		  id: 3,
		},
		{
		  img: 'https://cdn.riastatic.com/docs/pictures/common/0/99/9925/9925.jpg',
		  title: this.$t('Спілкуйтесь у Чаті'),
		  description: this.$t('Отримуйте миттєві сповіщення про нові повідомлення.'),
		  id: 4,
		},
		{
		  img: 'https://cdn.riastatic.com/docs/pictures/common/0/99/9926/9926.jpg',
		  title: this.$t('Продавайте швидко'),
		  description: this.$t('Додавайте свої пропозиції, редагуйте, піднімайте в ТОП та отримуйте дзвінки покупців.'),
		  id: 5,
		},
	  ]
	};
  },

  methods: {
	prev() {
	  if (this.index !== 0) {
		this.index -= 1;
	  } else {
		this.index = this.slides.length - 1;
	  }
	},
	next() {
	  if (this.index < this.slides.length - 1) {
		this.index += 1;
	  } else {
		this.index = 0;
	  }
	},
	goto(index) {
	  this.index = index;
	},
	touchstart(event) {
	  const {changedTouches: {0: {pageX: x} = {}} = {}} = event;
	  this.touch.x = x;
	},
	touchend(event) {
	  const {changedTouches: {0: {pageX: x} = {}} = {}} = event;
	  if (x - this.touch.x > 50) {
		this.prev();
	  }
	  if (this.touch.x - x > 50) {
		this.next();
	  }
	},
	clickEvent() {
	  this.gaEvent('main_news', this.onclickParams, `slider_${this.slides[0].id}`);
	},
  },

  mounted() {
	setInterval(() => {
	  this.next();
	}, 5000);

  },
  computed: {
	...mapGetters({
	  lang: 'lang/id',
	}),
	oneLink() {
	  if (this.os === 'ios') {
		return 'https://ad.apps.fm/BLU6cv8EEvi-73bwr7cnB_E7og6fuV2oOMeOQdRqrE2QRCSx0xu66NOS-Oqib1VdKRE1r0PTJ9hLX3dLCrKMitW494vk4_9zAiBfN3QEq-k';
	  }
	  if (this.os === 'android') {
		return 'https://ad.apps.fm/BYUP_lTZzbDmMZtHkqO9UV5KLoEjTszcQMJsV6-2VnHFDLXitVHB6BlL95nuoNYf5E1p4HIJsSgSZiMa1FOvpSSjG0A33quAu_e59dI7ZNb12ddFbrIOvX6UuImmXEWn';
	  }
	  if (this.os === 'huawei') {
		return 'https://appgallery.cloud.huawei.com/ag/n/app/C101464449?channelId=web+promo+page+auto.ria&referrer=web+promo+page+auto.ria&id=f38e9c2560d14c838d3ace0bc8181cde&s=B74C645CC50E67106596D2317A69B95E41D5314063944F021532BE6F6EBE0BF5&detailType=0&v=';
	  }
	  else return 'javascript:void(0);'
	},
	onclickParams(){
	  if (this.os === 'ios') {
		return 'Click_on_app_store';
	  }
	  if (this.os === 'android') {
		return 'Click_on_play_market';
	  }
	  if (this.os === 'huawei') {
		return 'Click_on_Huawei';
	  }
	},
  },
  i18n: require('./i18n').default,
};
